import { IS_BROWSER } from "$fresh/runtime.ts";

export default function BackLink({ text = "Back", className = "" }) {
  const handleClick = (e) => {
    e.preventDefault();
    if (IS_BROWSER) {
      window.history.back();
    }
  };

  return (
    <a
      href="#"
      onClick={handleClick}
      className={className}
    >
      ← {text}
    </a>
  );
}
